<template>
  <div>
    <NavBar class="fixed top-0 left-0 right-0"></NavBar>

    <secondary-navbar
      title="Edit Profile"
      :showbroker="false"
      :showaction="false"
      :showback="true"
    />

    <div class="w-full mt-6 grid grid-cols-10 hidden sm:grid" id="page-login">
      <div class="col-span-2 sm:block 0 background-f4">
        <left-side-strip :showBrokerButton="true" />
      </div>

      <div class="col-span-8">
        <div class="m-20">
          <div class="grid grid-cols-5 gap-4">
            <div class="col-span-2">
              <span class="oeno_portfolio_winetitle pt-10">First Name</span>
            </div>
            <div class="col-span-3">
              <md-field class="md-field-small">
                <md-input
                  v-model="firstname"
                  name="floating_first_name"
                  id="floating_first_name"
                  type="text"
                  required
                ></md-input>
              </md-field>
            </div>

            <div class="col-span-2">
              <span class="oeno_portfolio_winetitle pt-10">Last Name</span>
            </div>
            <div class="col-span-3">
              <md-field class="md-field-small">
                <md-input v-model="lastname" type="text" required></md-input>
              </md-field>
            </div>

            <div class="col-span-2">
              <span class="oeno_portfolio_winetitle pt-10">Telephone</span>
            </div>
            <div class="col-span-3">
              <md-field class="md-field-small">
                <md-input
                  type="tel"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  v-model="telephone"
                  required
                ></md-input>
              </md-field>
            </div>
            <div class="col-span-5">
              <hr class="oeno_divider_light" />
            </div>
            <div class="col-span-2">
              <span class="oeno_portfolio_winetitle pt-10">Address</span>
            </div>
            <div class="col-span-3">
              <md-field class="md-field-small">
                <md-input type="text" v-model="address1" required></md-input>
              </md-field>
            </div>

            <div class="col-span-2">
              <span class="oeno_portfolio_winetitle pt-10"></span>
            </div>
            <div class="col-span-3">
              <md-field class="md-field-small">
                <md-input v-model="address2"></md-input>
              </md-field>
            </div>

            <div class="col-span-2">
              <span class="oeno_portfolio_winetitle pt-10">Town</span>
            </div>
            <div class="col-span-3">
              <md-field class="md-field-small">
                <md-input v-model="town"></md-input>
              </md-field>
            </div>

            <div class="col-span-2">
              <span class="oeno_portfolio_winetitle pt-10">County</span>
            </div>
            <div class="col-span-3">
              <md-field class="md-field-small">
                <md-input type="text" v-model="county" required></md-input>
              </md-field>
            </div>
            <div class="col-span-2">
              <span class="oeno_portfolio_winetitle pt-10">Postcode</span>
            </div>
            <div class="col-span-3">
              <md-field class="md-field-small">
                <md-input type="text" v-model="postcode" required></md-input>
              </md-field>
            </div>
            <div class="col-span-2">
              <span class="oeno_portfolio_winetitle pt-10">Country</span>
            </div>
            <div class="col-span-3">
              <md-field class="md-field-small">
                <md-input v-model="country"></md-input>
              </md-field>
            </div>
          </div>

          <br />
          <br />
          <br />
          <br />
          <div class="flex justify-center">
            <button
              type="submit"
              @click="submitForm()"
              class="w-48 h-10 oeno_button_black"
            >
              {{ updateStatus ? "Updating...." : "Save Changes " }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import { HTTP } from "@/axios.js";
import NavBar from "@/components/layout/navbar.vue";
import SecondaryNavbar from "@/components/layout/SecondaryNavbar.vue";
import Footer from "@/components/layout/footer.vue";
import LeftSideStrip from "@/components/pageparts/LeftSideStrip.vue";

export default {
  components: {
    NavBar,
    Footer,
    LeftSideStrip,
    SecondaryNavbar,
  },
  data() {
    return {
      county: "",
      telephone: "",
      firstname: "",
      lastname: "",
      address1: "",
      address2: "",
      postcode: "",
      town: "",
      country: "",
      updateStatus: false,
    };
  },
  created: function () {
    this.setDefaultData();
  },
  methods: {
    setDefaultData() {
      this.county = this.$store.state.user.county;
      this.telephone = this.$store.state.user.telephone;
      this.firstname = this.$store.state.user.firstname;
      this.lastname = this.$store.state.user.surname;
      this.address1 = this.$store.state.user.address_line1;
      this.address2 = this.$store.state.user.address_line2;
      this.postcode = this.$store.state.user.postcode;
      this.town = this.$store.state.user.town;
      this.country = this.$store.state.user.country;
    },
    submitForm() {
      // this.setState(prevState => {
      //   return {
      //     errorMessage: ''
      //   };
      // });

      // const firstname = returnData.firstname;
      // const surname = returnData.surname;
      // const telephone = returnData.telephone;
      // const address_line1 = returnData.address_line1;
      // const address_line2 = returnData.address_line2;
      // const town = returnData.town;
      // const county = returnData.county;
      // const country = returnData.country;
      // const postcode = returnData.postcode;
      // if (this.firstname != "" && this.lastname != "" && this.address1 != "" && this.town != "" && this.country != "") {
      this.updateStatus = true;
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.token,
      };

      HTTP.post(
        "/api/update-profile",
        {
          firstname: this.firstname,
          surname: this.lastname,
          telephone: this.telephone,
          address_line1: this.address1,
          address_line2: this.address2,
          town: this.town,
          county: this.county,
          country: this.country,
          postcode: this.postcode,
        },
        { headers: headers }
      )
        .then((rsp) => {
          if (rsp.data.statuscode == 200) {
            this.updateProfile();
            this.setDefaultData();
            this.updateStatus = false;

            this.$router.go(-1);
          } else {
            this.updateStatus = false;

            if (rsp.data.message) {
              this.$notify({
                group: "oeno",
                title: "System Error",
                text: rsp.data.message,
                type: "error",
              });
            } else {
              this.$notify({
                group: "oeno",
                title: "Update Error",
                text: "profileeditfail",
                type: "error",
              });
            }
          }
        })
        .catch((err) => {
          this.$notify({
            group: "oeno",
            title: "System Error",
            text: err,
            type: "error",
          });
          this.updateStatus = false;
        });
      // }
    },
  },
};
</script>
